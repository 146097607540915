import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import ImproveYourLife from '../components/ImproveYourLife';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Stress Management" />

    <div>
      <div className="section image-bg-section">
        <div className="container">
          <div className="content-width-medium">
            <h6 className="subheading">Stress Management</h6>
            <h1 className="no-bottom-space center-mobile">Relieve stress and feel consistently relaxed and happy</h1><Link to="/pricing/" className="button margin-top w-inline-block">
              <div>Get started</div>
            </Link>
          </div>
        </div>
        <div style={{backgroundImage: 'url("/images/5ddc26dcb824216371bd21ca_work-time_t20_LA84X7.jpg")'}} className="image-background bg-image-1" />
        <div className="background-scrim-left" />
      </div>
      <div className="section">
        <div className="container">
          <div className="w-richtext">
            <p>Free yourself from harmful stress in all situations and take immediate control of your physical and mental well-being.</p>
            <p>Relieving yourself from harmful stress may be one of the most important things you’ll do for yourself. The WiseGuide App is designed to help you release negative feelings, experience renewed energy, and become more natural, comfortable, and effective in every area of your life.</p>
            <p>Start a FREE 7-day trial to experience how The WiseGuide App will help you relieve stress and depression. Just listen.</p>
          </div>
        </div>
      </div>
    </div>

    <ImproveYourLife />

    <DownloadApp />
  </Layout>
);
